<template>
  <div
    v-if="!isLoading"
    id="user-view"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <!--<v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiAccountOutline }}
            </v-icon>
            <span>{{ $t('lbl.info') }}</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiLockOutline }}
            </v-icon>
            <span>{{ $t('lbl.password') }}</span>
          </v-tab>
          <v-tab v-if="user.id_father === null && user.company_id !== null">
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiDomain }}
            </v-icon>
            <span>{{ $t('lbl.companyConfiguration') }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-info></user-tab-info>
          </v-tab-item>
          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <v-tab-item>
            <user-tab-company></user-tab-company>
          </v-tab-item>
        </v-tabs-items>-->

        <v-tabs
          v-model="userTab"
          show-arrows
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiAccountOutline }}
            </v-icon>
            <span>{{ $t('lbl.account') }}</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiLockOpenOutline }}
            </v-icon>
            <span>{{ $t('lbl.security') }}</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiInformationOutline }}
            </v-icon>
            <span>Info</span>
          </v-tab>
          <v-tab v-if="user.nivel === 1 && user.id_father === null">
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiInformationOutline }}
            </v-icon>
            <span>{{ $t('lbl.about') }}</span>
          </v-tab>
          <!--<v-tab v-if="user.nivel === -1">
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiFileExportOutline }}
            </v-icon>
            <span>Export</span>
          </v-tab> -->
          <!--<v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiBookmarkOutline }}
            </v-icon>
            <span>Billing</span>
          </v-tab>-->
          <!--<v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icon.mdiBellOutline }}
            </v-icon>
            <span>Notifications</span>
          </v-tab>-->
        </v-tabs>

        <!-- tabs item -->
        <v-tabs-items v-model="userTab">
          <v-tab-item>
            <account-settings-account :account-data="user"></account-settings-account>
          </v-tab-item>

          <v-tab-item>
            <account-settings-security :account-data="user"></account-settings-security>
          </v-tab-item>

          <v-tab-item>
            <account-settings-info :account-data="user"></account-settings-info>
          </v-tab-item>
          <v-tab-item v-if="user.nivel === 1 && user.id_father === null">
            <AccountSettingsAbout :user="user" />
          </v-tab-item>

          <v-tab-item v-if="user.nivel === -1">
            <!--<Export /> -->
            <!--<Newletter />-->
          </v-tab-item>

          <!--<v-tab-item>
            <account-settings-billing></account-settings-billing>
          </v-tab-item>-->
          <!--<v-tab-item>
            <account-settings-notification :notification-data="user"></account-settings-notification>
          </v-tab-item>-->
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiLockOutline,
  mdiDomain,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLinkVariant,
  mdiEmailNewsletter,
  mdiFileExportOutline,
} from '@mdi/js'

// import UserTabInfo from './user-tab-info/UserTabInfo.vue'
// import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'
// import UserTabCompany from './user-tab-billings-plans/UserTabBillingsPlans.vue'

// import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
// import UserTabConnections from './user-tab-connections/UserTabConnections.vue'

import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsInfo from './AccountSettingsInfo.vue'
import AccountSettingsAbout from './AccountSettingsAbout.vue'

// import Export from './export/form/Export.vue'

// import Newletter from './newletter/form/Newletter.vue'

// import AccountSettingsBilling from './AccountSettingsBilling.vue'

// import AccountSettingsNotification from './AccountSettingsNotification.vue'

export default {
  components: {
    /* UserTabInfo,
    UserTabSecurity,
    UserTabCompany,

    UserTabBillingsPlans,
    UserTabNotifications,
    UserTabConnections, */
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
    AccountSettingsAbout,

    // Export,

    // Newletter,

    // AccountSettingsBilling,

    // AccountSettingsNotification,
  },
  data() {
    return {
      isPlanUpgradeDialogVisible: false,
      isLoading: true,
      userTab: null,
      icon: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiLockOutline,
        mdiDomain,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiLinkVariant,
        mdiEmailNewsletter,
        mdiFileExportOutline,
      },
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
  },
  created() {
    if (sessionStorage.getItem('tabPerfil') === null) {
      this.userTab = null
    } else {
      // eslint-disable-next-line radix
      this.userTab = parseInt(sessionStorage.getItem('tabPerfil'))
    }
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data

          // console.log(this.user)
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
  },

  /* setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref({})
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = {}
        }
      })

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      userTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      userData,
      resolveCurrentPlanValue,
    }
  }, */
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
